.ql-editor {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ql-snow.ql-toolbar {
  background-color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  border-color: #e4e4e7;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.ql-snow.ql-toolbar button {
  height: 20px;
  width: 24px;
}

.ql-container.ql-snow {
  border-color: #e4e4e7;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  z-index: 0;
}
